import React from "react"
import PageHeader from "../components/PageHeader"
import Layout from "../components/Layout"
import Container from "../components/Container"
import PageDivider from "../components/PageDivider"
import SignupForm from "../components/SignupForm"
import ThreeColumns from "../components/ThreeColumns"
import SectionHeading from "../components/SectionHeading"
import IllustratedContent from "../components/IllustratedContent"
import SEO from "../components/seo"
import background from "../images/smiling.jpg"

const Cup = () => {
  return (
    <Layout>
      <SEO title="Menstrual Cup" />
      <PageHeader background={background} />
      <Container>
        <SectionHeading title="Get Involved">
          We have just started our journey but have lots planned. If you would
          like to get involed you can contact us directly or signup to our
          mailing list to stay up to date with our progress.
          <SignupForm />
        </SectionHeading>
      </Container>
    </Layout>
  )
}

export default Cup
