import React from "react"
import styled from "styled-components"
import Button from "../Button"

const Container = styled.div`
  padding-top: 20px;
  text-align: center;
`

const Input = styled.input`
  width: 100%;
  height: 50px;
  outline: none;
  color: #ff5e84;
  font-size: 17px;
  appearance: none;
  max-width: 400px;
  font-weight: 500;
  text-align: center;
  margin-bottom: 24px;
  border-radius: 25px;
  border: 1px solid #ddcfd2;
  box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.05);

  &:focus {
    border-color: #ff5e84;
  }

  &::placeholder {
    color: #ddcfd2;
  }
`

const SignupForm = () => {
  return (
    <Container id="mc_embed_signup">
      <form
        action="https://sondercharity.us4.list-manage.com/subscribe/post?u=7d88d3bcfec324aed2725cc99&amp;id=394d2a2046"
        method="post"
        id="mc-embedded-subscribe-form"
        name="mc-embedded-subscribe-form"
        className="validate"
        target="_blank"
        novalidate
      >
        <div id="mc_embed_signup_scroll">
          <div className="mc-field-group">
            <Input
              type="email"
              defaultValue=""
              name="EMAIL"
              className="email"
              id="mce-EMAIL"
              placeholder="Email Address"
            />
          </div>
          <div id="mce-responses" class="clear">
            <div
              class="response"
              id="mce-error-response"
              style={{ display: "none" }}
            ></div>
            <div
              class="response"
              id="mce-success-response"
              style={{ display: "none" }}
            ></div>
          </div>
          <div
            style={{ position: "absolute", left: "-5000px" }}
            aria-hidden="true"
          >
            <input
              type="text"
              name="b_7d88d3bcfec324aed2725cc99_394d2a2046"
              tabindex="-1"
              value=""
            />
          </div>
          <Button
            as="input"
            type="submit"
            value="Subscribe"
            name="subscribe"
            id="mc-embedded-subscribe"
            class="button"
          />
        </div>
      </form>
    </Container>
  )
}

export default SignupForm
